import "./App.css";
import ApiCall from "./ApiCall";
import { Fragment } from "react";

function App() {
  return (
    <Fragment>
      <div className="root">
        <div className="content">
          <div className="main">
            <h2>ZZ - Lightersaber Pharmacy</h2>
            <h3>CPRID: 200138101</h3>
            <ApiCall />
          </div>
          <div className="instructions">
            <TableOfResponses />
            <HorizontalRule />
          </div>
        </div>
        <div className="workInProgress">
          <WorkInProgress />
        </div>
      </div>
    </Fragment>
  );
}

const TableOfResponses = () => {
  return (
    <table>
      <tr>
        <th>Task Type</th>
        <th>Quantity Value</th>
        <th>PMS Task Response</th>
      </tr>
      <tr>
        <td>
          <ul>
            <li> e110 New Prescription</li>
            <li> e120 Renewal Prescription</li>
            <li> e162 Approve RX Response</li>
            <li> e163 Approve with Changes RX Response</li>
          </ul>
        </td>
        <td>30</td>
        <td>p200 Dispense Notification</td>
      </tr>
      <tr>
        <td>
          <ul>
            <li> e110 New Prescription</li>
            <li> e120 Renewal Prescription</li>
            <li> e162 Approve RX Response</li>
            <li> e163 Approve with Changes RX Response</li>
          </ul>
        </td>
        <td>45</td>
        <td>p200 Dispense Notification & p210 Dispense Cancel Notification</td>
      </tr>
      <tr>
        <td>
          <ul>
            <li> e110 New Prescription</li>
            <li> e162 Approve RX Response</li>
            <li> e163 Approve with Changes RX Response</li>
          </ul>
        </td>
        <td>90</td>
        <td>
          Non Split p160 Renewal Request
          <br /> p200 Dispense Notification
        </td>
      </tr>
      <tr>
        <td>
          <ul>
            <li> e120 Renewal Prescription</li>
            <li> e162 Approve RX Response</li>
            <li> e163 Approve with Changes RX Response</li>
          </ul>
        </td>
        <td>180</td>
        <td>
          Single Split p160 Renewal Request
          <br />
          p200 Dispense Notification
          <br />
          p210 Dispense Cancel Notification
        </td>
      </tr>
      <tr>
        <td>
          <ul>
            <li> e120 Renewal Prescription</li>
            <li> e162 Approve RX Response</li>
            <li> e163 Approve with Changes RX Response</li>
          </ul>
        </td>
        <td>360</td>
        <td>Full Split p160 Renewal Request</td>
      </tr>
      <tr>
        <ul>
          <li>e140 Cancel RX Request</li>
        </ul>
        <td>35</td>
        <td>p142 Cancel Rx Request Approved</td>
      </tr>
      <tr>
        <ul>
          <li>e140 Cancel RX Request</li>
        </ul>
        <td>55</td>
        <td>p141 Cancel Rx Request Denied</td>
      </tr>
      <tr>
        <ul>
          <li>e140 Cancel RX Request</li>
        </ul>
        <td>95</td>
        <td>p143 Cancel Rx Request Remaining Fills Revoked</td>
      </tr>
      <tr>
        <td>Any</td>
        <td>--</td>
        <td>
          If one medication order does not match a condition, the entire bundle
          will be rejected with a 999 Error Notification
        </td>
      </tr>
    </table>
  );
};

const HorizontalRule = () => {
  return (
    <div>
      <hr
        style={{
          backgroundColor: "black",
          color: "black",
          height: "3px",
        }}
      />
    </div>
  );
};
const WorkInProgress = () => {
  return (
    <Fragment>
      <h3>Work in Progress</h3>
      <p>Waiting for suggestions... feel free to suggest!</p>
    </Fragment>
  );
};
export default App;
