import React, { useState } from "react";
import "./ApiCall.css";

function ApiCall() {
  const [response, setResponse] = useState("");
  const [isLoading, setLoading] = useState(false);
  const apiEndpoint = "https://emr.edmondchiu.ca/api/v1/pharmacy/pollInbox"
  // const apiEndpoint = "http://localhost:8081/api/v1/pharmacy/pollInbox"

  const handleClick = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        apiEndpoint
      );
      const data = await res.text();
      setResponse(data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <div className="apiCall">
      <button onClick={handleClick}>Poll Pharmacy Inbox and Auto Respond</button>
        <p>{isLoading ? "polling and responding..." : response}</p>
    </div>
  );
}

export default ApiCall;
